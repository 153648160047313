import React, { useState, useCallback, useEffect } from 'react';
import useStyles from './styles';
import {
    Card,
    CardActions,
    CardMedia,
    Button,
    Typography,
    Box
} from '@mui/material';
import { Link } from 'react-router-dom';

// assets
import musicNote from '../../../images/music_note_icon.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// redux
import { useDispatch } from 'react-redux';
import { deletePost } from '../../../actions/posts';

const Post = React.memo(({ post, onPlayStop, currentId, user }) => {
    // state
    const [isPlaying, setIsPlaying] = useState(currentId === post?._id);

    // hooks
    const dispatch = useDispatch();
    const classes = useStyles();

    // data
    // use placeholder image if none were set
    const img = !!post.trackImage ? post.trackImage : musicNote;
    const audioFile = post.audioFile ?? '';
    const isAdmin = user?.isAdmin;

    // actions
    const handleDelete = () => {
        dispatch(deletePost(post._id));
    };

    const handlePlayStop = useCallback(() => {
        setIsPlaying(!isPlaying);
        onPlayStop(post?._id);
    }, [isPlaying, onPlayStop, post]);

    useEffect(() => {
        if (currentId !== post?._id) {
            setIsPlaying(false);
        }
    }, [currentId, post]);

    return (
        <Card className={classes.card}>
            <Box className={classes.imageTitle}>
                <CardMedia className={classes.media} image={img} />
                <Typography className={classes.title} variant="h5" gutterBottom>
                    {post.title}
                </Typography>
            </Box>
            <CardActions className={classes.cardActions}>
                <Button
                    size="small"
                    color="primary"
                    onClick={() => handlePlayStop(audioFile)}
                >
                    {isPlaying ? (
                        <StopCircleIcon
                            className={classes.playPauseIcon}
                            fontSize="small"
                        />
                    ) : (
                        <PlayCircleOutlineIcon
                            className={classes.playPauseIcon}
                            fontSize="small"
                        />
                    )}
                </Button>
                {!!isAdmin && (
                    <Box className="flex items-center">
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleDelete}
                        >
                            <DeleteIcon fontSize="small" />
                        </Button>
                        <Button
                            component={Link}
                            to={`/newTrack/${post?._id}`}
                            size="small"
                            color="secondary"
                        >
                            <MoreVertIcon fontSize="small" />
                        </Button>
                    </Box>
                )}
            </CardActions>
        </Card>
    );
});
export default Post;
