import React, { useEffect, useRef } from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

// redux
import { useSelector } from 'react-redux';

const PleaseWait = React.memo((props) => {
    const { backToStart } = props;
    const didMount = useRef(false);

    // redux
    const isLoading = useSelector((state) => state.albums.loading);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        backToStart();
    }, [isLoading]); // eslint-disable-line

    useEffect(() => {
        // component did mount
        if (!didMount.current) {
            didMount.current = true;
        }
    }, []);

    // render
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Typography color="secondary" variant="h6" display="inline" mr={2}>
                Uploading tracks. This can take a few minutes...
            </Typography>
            <CircularProgress />
        </Box>
    );
});

export default PleaseWait;
