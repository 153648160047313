import * as api from '../api';
import {
    FETCH_ALBUMS_REQUEST,
    FETCH_ALBUMS_SUCCESS,
    FETCH_ALBUMS_ERROR,
    CREATE_ALBUMS_REQUEST,
    CREATE_ALBUMS_SUCCESS,
    CREATE_ALBUMS_ERROR,
    FETCH_ALBUM_REQUEST,
    FETCH_ALBUM_SUCCESS,
    FETCH_ALBUM_ERROR,
    FETCH_ALBUM_TRACK_SUCCESS,
    FETCH_ALBUM_TRACK_ERROR
} from '../constants/actionTypes';

export const getAlbums = (page, cb) => async (dispatch) => {
    dispatch({ type: FETCH_ALBUMS_REQUEST });

    try {
        const { data } = await api.fetchAlbums(page);
        dispatch({
            type: FETCH_ALBUMS_SUCCESS,
            payload: data,
            params: { page }
        });
        cb?.();
    } catch (error) {
        dispatch({
            type: FETCH_ALBUMS_ERROR,
            payload: error.response.data?.message
        });
    }
};

const getAlbumTracks = (trackIds) => async (dispatch) => {
    trackIds?.forEach(async (id, index) => {
        try {
            const { data } = await api.fetchPost(id);
            dispatch({
                type: FETCH_ALBUM_TRACK_SUCCESS,
                payload: data,
                params: { index }
            });
        } catch (error) {
            dispatch({
                type: FETCH_ALBUM_TRACK_ERROR,
                payload: error.response.data?.message
            });
        }
    });
};

export const getAlbum = (id) => async (dispatch) => {
    dispatch({ type: FETCH_ALBUM_REQUEST });

    try {
        const { data: response } = await api.fetchAlbum(id);
        const trackIds = response?.tracks;
        dispatch(getAlbumTracks(trackIds));
        dispatch({ type: FETCH_ALBUM_SUCCESS, payload: response });
    } catch (error) {
        dispatch({
            type: FETCH_ALBUM_ERROR,
            payload: error.response.data.message
        });
    }
};

export const createAlbum = (data) => async (dispatch) => {
    dispatch({ type: CREATE_ALBUMS_REQUEST });

    try {
        const { response } = await api.createAlbum(data);
        dispatch({ type: CREATE_ALBUMS_SUCCESS, payload: response });
    } catch (error) {
        dispatch({
            type: CREATE_ALBUMS_ERROR,
            payload: error.response.data.message
        });
    }
};

export const getAlbumByTitle = (title) => async (dispatch) => {
    dispatch({ type: FETCH_ALBUM_REQUEST });

    try {
        const { data: response } = await api.fetchAlbumByTitle(title);
        const trackIds = response?.tracks;
        dispatch(getAlbumTracks(trackIds));
        dispatch({ type: FETCH_ALBUM_SUCCESS, payload: response });
    } catch (error) {
        dispatch({
            type: FETCH_ALBUM_ERROR,
            payload: error.response.data.message
        });
    }
};
