import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import BuildIcon from '@mui/icons-material/Build';

// utils
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../utils/auth';

// config
const drawerWidth = 240;

const ResponsiveDrawer = React.memo((props) => {
    const { open, toggleDrawer } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authUser = useSelector((state) => state.auth.authData);
    const isAdmin = authUser?.isAdmin;

    // actions
    const logout = useCallback(async () => {
        const logoutSuccess = await signOut();
        if (logoutSuccess) {
            dispatch({ type: 'LOGOUT' });
            navigate('/');
        }
    }, [dispatch, navigate]);

    const onMenuSelection = useCallback(
        (route) => {
            toggleDrawer();
            navigate(route);
        },
        [navigate, toggleDrawer]
    );

    // render
    if (location?.pathname?.includes('admin')) {
        return null;
    }

    const drawer = (
        <Box className="bg-black">
            <Toolbar>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon className="text-white" />
                </IconButton>
            </Toolbar>
            <Divider className="bg-white" />
            {!!authUser ? (
                <List>
                    <ListItem key="username">
                        <ListItemIcon className="text-white">
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={authUser?.username}
                            className="text-white"
                        />
                    </ListItem>
                    <ListItem key="newTrack" disablePadding>
                        {isAdmin ? (
                            <ListItemButton
                                onClick={() => onMenuSelection('newTrack')}
                            >
                                <ListItemIcon className="text-white">
                                    <UploadFileIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Upload Tracks'}
                                    className="text-white"
                                />
                            </ListItemButton>
                        ) : null}
                    </ListItem>
                    <ListItem key="logout" disablePadding>
                        <ListItemButton onClick={logout}>
                            <ListItemIcon className="text-white">
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Logout'}
                                className="text-white"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            ) : (
                <List>
                    <ListItem key="login" disablePadding>
                        <ListItemButton onClick={() => onMenuSelection('auth')}>
                            <ListItemIcon className="text-white">
                                <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Login'}
                                className="text-white"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            )}
            <Divider className="bg-white" />
            <List>
                <ListItem key="tracks" disablePadding>
                    <ListItemButton onClick={() => onMenuSelection('/')}>
                        <ListItemIcon className="text-white">
                            <AudiotrackIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Tracks'}
                            className="text-white"
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem key="albums" disablePadding>
                    <ListItemButton onClick={() => onMenuSelection('albums')}>
                        <ListItemIcon className="text-white">
                            <LibraryMusicIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Albums'}
                            className="text-white"
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem key="tools" disablePadding>
                    <ListItemButton onClick={() => onMenuSelection('tools')}>
                        <ListItemIcon className="text-white">
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Tools'}
                            className="text-white"
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box className="flex">
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#000000',
                        borderRight: '1px solid #ffffff'
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                {drawer}
            </Drawer>
        </Box>
    );
});

export default ResponsiveDrawer;
