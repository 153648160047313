import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    fileInputWrap: {
        width: '97%',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    fileInput: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 10,
        '& input': {
            color: '#ffffff'
        }
    },
    textInput: {
        backgroundColor: '#ffffff',
        borderRadius: '10px'
    }
}));
