import React, { useState, useCallback } from 'react';
import useStyles from './styles';
import {
    Button,
    Typography,
    Paper,
    CircularProgress,
    Container,
    Grid,
    Tabs,
    Tab
} from '@mui/material';
import { Link } from 'react-router-dom';
import SingleTrackForm from './Tabs/SingleTrackForm/SingleTrackForm';
import AlbumForm from './Tabs/AlbumForm/AlbumForm';

// redux
import { useSelector } from 'react-redux';

const Form = React.memo(() => {
    // data
    const [tabIndex, setTabIndex] = useState(0);

    // style
    const classes = useStyles();

    // redux
    const loadingState = useSelector((state) => state.posts.loading);
    const user = useSelector((state) => state.auth.authData);
    const isAdmin = user?.isAdmin;

    const handleTabChange = useCallback((_event, newValue) => {
        setTabIndex(newValue);
    }, []);

    // render

    if (!user) {
        return (
            <Container component="main" maxWidth="xs">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color="secondary" variant="h6">
                            Please login to upload a Track.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/auth"
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    if (!!user && !isAdmin) {
        return (
            <Container component="main" maxWidth="xs">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            className="text-center"
                            color="primary"
                            variant="h6"
                        >
                            Insufficient user scope.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="lg">
            <Paper className={classes.paper}>
                {loadingState ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            textColor="secondary"
                        >
                            <Tab
                                label="Single Track"
                                sx={{ color: 'tertiary.main' }}
                            />
                            <Tab
                                label="Album"
                                sx={{ color: 'tertiary.main' }}
                            />
                        </Tabs>
                        <SingleTrackForm currentTabIndex={tabIndex} index={0} />
                        <AlbumForm currentTabIndex={tabIndex} index={1} />
                    </>
                )}
            </Paper>
        </Container>
    );
});

export default Form;
