import {
    FETCH_ALL,
    FETCH_SINGLE,
    CREATE,
    UPDATE,
    DELETE,
    LIKE,
    CREATE_REQUEST,
    CREATE_ERROR,
    UPDATE_ERROR,
    FETCH_ERROR,
    SET_POST,
    FETCH_REQUEST,
    UPDATE_REQUEST
} from '../constants/actionTypes';

const INITIAL_STATE = {
    posts: [],
    post: {},
    nextPage: null,
    loading: false,
    success: null,
    error: null
};

const postsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE:
        case LIKE:
            return {
                ...state,
                posts: state.posts.map((post) =>
                    post._id === action.payload._id ? action.payload : post
                ),
                success: true,
                loading: false
            };
        case DELETE:
            return {
                ...state,
                posts: state.posts.filter(
                    (post) => post._id !== action.payload
                ),
                success: null,
                loading: false
            };
        case FETCH_ALL:
            const prevNextPage = state.nextPage;
            const resNextPage = action?.payload?.nextPage;
            const prevPosts = state.posts;

            if (resNextPage === prevNextPage) {
                return state;
            }

            return {
                ...state,
                posts: prevPosts.concat(action?.payload?.data),
                nextPage: resNextPage,
                success: null,
                loading: false
            };
        case FETCH_SINGLE:
            return {
                ...state,
                post: action?.payload,
                loading: false
            };
        case SET_POST:
            return {
                ...state,
                post: action?.payload
            };
        case CREATE:
            return {
                ...state,
                posts: [...state.posts, action.payload],
                success: true,
                loading: false
            };
        case FETCH_REQUEST:
        case CREATE_REQUEST:
        case UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_ERROR:
        case UPDATE_ERROR:
        case FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default postsReducer;
