import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    appBar: {
        borderRadius: 15,
        backgroundColor: '#000000',
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    heading: {
        color: '#ffffff',
        textDecoration: 'none',
        fontSize: '2.3em',
        textShadow: '4px 4px 6px'
    },
    image: {
        marginLeft: '15px'
    },
    toolbar: {
        display: 'flex'
    },
    profile: {
        display: 'flex'
    },
    userName: {
        display: 'flex',
        alignItems: 'center'
    },
    brandContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));
