import React from 'react';
import useStyles from './styles';
import { Button, Typography, Box } from '@mui/material';

const FinishedTracks = React.memo((props) => {
    // data
    const { track, onEdit } = props;

    // styles
    const classes = useStyles();

    // render
    return (
        <Box className={classes.finishedTrackBox}>
            <Typography color="secondary" variant="h6" display="inline" mr={2}>
                {track?.title}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onEdit}
            >
                Edit
            </Button>
        </Box>
    );
});

export default FinishedTracks;
