import {
    FETCH_ALBUMS_REQUEST,
    FETCH_ALBUMS_SUCCESS,
    FETCH_ALBUMS_ERROR,
    FETCH_ALBUM_REQUEST,
    FETCH_ALBUM_SUCCESS,
    FETCH_ALBUM_ERROR,
    CREATE_ALBUMS_SUCCESS,
    CREATE_ALBUMS_REQUEST,
    CREATE_ALBUMS_ERROR,
    FETCH_ALBUM_TRACK_SUCCESS
} from '../constants/actionTypes';

const INITIAL_STATE = {
    albums: [],
    album: {},
    nextPage: null,
    loading: false,
    success: null,
    error: null
};

const albumsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_ALBUMS_REQUEST:
        case FETCH_ALBUM_REQUEST:
        case FETCH_ALBUMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_ALBUMS_ERROR:
        case FETCH_ALBUMS_ERROR:
        case FETCH_ALBUM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: null
            };
        case CREATE_ALBUMS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            };
        case FETCH_ALBUMS_SUCCESS:
            const reqPage = action?.params?.page;
            const prevNextPage = state.nextPage;
            const resNextPage = action?.payload?.nextPage;
            const prevAlbums = state.albums;

            if (resNextPage === prevNextPage && reqPage !== 1) {
                return state;
            }

            return {
                ...state,
                albums: prevAlbums.concat(action?.payload?.data),
                nextPage: resNextPage,
                success: null,
                loading: false
            };
        case FETCH_ALBUM_SUCCESS:
            return {
                ...state,
                album: action?.payload,
                loading: false,
                error: null
            };
        case FETCH_ALBUM_TRACK_SUCCESS:
            const tracks = state.album.tracks;
            const index = action?.params?.index;

            if (index !== -1) {
                tracks[index] = action?.payload;
            }

            return {
                ...state,
                album: {
                    ...state.album,
                    tracks
                }
            };
        default:
            return state;
    }
};

export default albumsReducer;
