import React, { useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import TrackList from './TrackList';

// assets
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// connect
import { useSelector, useDispatch } from 'react-redux';
import { getAlbum, getAlbumByTitle } from '../../actions/albums';

// utils
import { formatTitleForApi } from '../../utils/posts';

const SingleAlbumPage = (props) => {
    const { setShowSingleAlbum, id, setTrackIndex, trackIndex, title } = props;

    // connect
    const dispatch = useDispatch();
    const album = useSelector((state) => state?.albums?.album);
    const loading = useSelector((state) => state.albums?.loading);

    // actions
    useEffect(() => {
        if (!!title && !album.title) {
            const formattedTitle = formatTitleForApi(title);
            return dispatch(getAlbumByTitle(formattedTitle));
        }

        const shouldFetch = !!id && id !== album?._id;
        shouldFetch && dispatch(getAlbum(id));
    }, [id, dispatch, album, title]);

    const navigateBack = useCallback(() => {
        setShowSingleAlbum(false);
    }, [setShowSingleAlbum]);

    return (
        <Box className="flex flex-col justify-center items-center w-full">
            <Box className="flex justify-start w-full">
                <IconButton
                    color="secondary"
                    aria-label="navigate back"
                    edge="start"
                    onClick={navigateBack}
                >
                    <ChevronLeftIcon fontSize="large" />
                </IconButton>
            </Box>
            {loading ? (
                <CircularProgress />
            ) : (
                <Box className="flex flex-col items-center justify-center">
                    <Box
                        className="flex justify-center items-center rounded-lg"
                        sx={{
                            backgroundImage: `url(${album?.albumArtwork})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: 250,
                            width: 250
                        }}
                    />
                    <Typography className="text-center text-lg text-white tracking-wider mt-4">
                        {album?.title}
                    </Typography>
                    <Typography className="text-center text-lg text-primary tracking-wider mb-4">
                        {album?.creator}
                    </Typography>
                    <TrackList
                        setTrackIndex={setTrackIndex}
                        trackIndex={trackIndex}
                    />
                </Box>
            )}
        </Box>
    );
};

export default React.memo(SingleAlbumPage);
