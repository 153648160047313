import React, { useCallback } from 'react';
import { Button, Box } from '@mui/material';
import SingleTrackRow from './SingleTrackRow';

// styles
import useStyles from './styles';

const MultiTrackInput = React.memo((props) => {
    // data
    const { tracks, addTrack, updateTracks, toNextStep, toPreviousStep } =
        props;
    const hasAllInfo = tracks?.length > 1;

    // styles
    const classes = useStyles();

    // actions
    const onFinish = useCallback(() => {
        toNextStep();
    }, [toNextStep]);

    if (!tracks.length) {
        return null;
    }

    // render
    return (
        <Box>
            {tracks?.map((track, index) => (
                <SingleTrackRow
                    key={track?.title ?? index}
                    track={track}
                    index={index}
                    updateTracks={updateTracks}
                />
            ))}
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ marginBottom: 2 }}
                onClick={addTrack}
            >
                Add Another Track
            </Button>
            <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={onFinish}
                disabled={!hasAllInfo}
                fullWidth
            >
                Next
            </Button>
            <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                onClick={toPreviousStep}
                fullWidth
            >
                Previous
            </Button>
        </Box>
    );
});

export default MultiTrackInput;
