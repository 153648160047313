import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 120
    },
    smMargin: {
        margin: theme.spacing(1)
    },
    actionDiv: {
        textAlign: 'center'
    }
}));
