import React, { useState, useCallback, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import AlbumInfoInput from './AlbumInfoInput';
import MultiTrackInput from './MultiTrackInput';
import PleaseWait from './PleaseWait';

// redux
import { useSelector } from 'react-redux';

// config
const STEP_MAP = [
    { key: 'album_info' },
    { key: 'add_tracks' },
    { key: 'please_wait' }
];

const MultiStepContainer = React.memo((props) => {
    // data
    const {
        addTrack,
        updateTracks,
        tracks,
        albumInfo,
        setAlbumInfo,
        onSubmit
    } = props;

    // state
    const [stepIndex, setStepIndex] = useState(0);

    // redux
    const success = useSelector((state) => state.albums.success);
    const error = useSelector((state) => state.albums.error);

    // actions
    const toNextStep = useCallback(() => {
        const nextStepKey = STEP_MAP[stepIndex + 1].key;

        if (nextStepKey === 'please_wait') {
            onSubmit();
        }

        setStepIndex(stepIndex + 1);
    }, [stepIndex, onSubmit]);

    const toPreviousStep = useCallback(
        () => setStepIndex(stepIndex - 1),
        [stepIndex]
    );

    const backToStart = useCallback(() => setStepIndex(0), []);

    // render
    const stepNode = useMemo(() => {
        const stepKey = STEP_MAP[stepIndex].key;

        switch (stepKey) {
            case 'album_info':
                return (
                    <AlbumInfoInput
                        albumInfo={albumInfo}
                        setAlbumInfo={setAlbumInfo}
                        toNextStep={toNextStep}
                    />
                );
            case 'add_tracks':
                return (
                    <MultiTrackInput
                        addTrack={addTrack}
                        updateTracks={updateTracks}
                        tracks={tracks}
                        toNextStep={toNextStep}
                        toPreviousStep={toPreviousStep}
                    />
                );
            case 'please_wait':
                return <PleaseWait backToStart={backToStart} />;
            default:
                return null;
        }
    }, [
        addTrack,
        albumInfo,
        setAlbumInfo,
        updateTracks,
        tracks,
        stepIndex,
        toNextStep,
        toPreviousStep,
        backToStart
    ]);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                {success && (
                    <Typography color="success.main" variant="h6">
                        Successfully added album!
                    </Typography>
                )}
                {error && (
                    <Typography
                        textAlign="center"
                        color="error.main"
                        variant="h6"
                    >
                        {error}
                    </Typography>
                )}
                <Typography textAlign="center" color="secondary" variant="h6">
                    ADD ALBUM
                </Typography>
            </Box>
            {stepNode}
        </Box>
    );
});

export default MultiStepContainer;
