import React, { useEffect, useCallback, useState } from 'react';
import { Box, Typography, Slide } from '@mui/material';
import AlbumList from './AlbumList';
import SingleAlbumPage from './SingleAlbumPage';
import TrackPlayer from '../Track/TrackPlayer';

// connect
import { useSelector } from 'react-redux';

// utils
import { useParams } from 'react-router-dom';

const AlbumsContainer = (props) => {
    // state
    const [showSingleAlbum, setShowSingleAlbum] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [trackIndex, setTrackIndex] = useState(0);
    const [title, setTitle] = useState(useParams()?.title);

    // redux
    const currentAlbum = useSelector((state) => state?.albums?.album);
    const error = useSelector((state) => state?.albums?.error);

    // data
    const tracks = currentAlbum?.tracks;
    const tracksLength = tracks?.length;
    const selectedTrack = tracks?.[trackIndex] ?? null;

    // actions

    const onClickNext = useCallback(() => {
        if (trackIndex === tracksLength - 1) {
            return;
        }

        setTrackIndex(trackIndex + 1);
    }, [trackIndex, tracksLength]);

    const onClickPrevious = useCallback(() => {
        if (trackIndex === 0) {
            return;
        }

        setTrackIndex(trackIndex - 1);
    }, [trackIndex]);

    useEffect(() => {
        if (!!title) {
            setShowSingleAlbum(true);
        }
    }, []); // eslint-disable-line

    // render

    return (
        <Box className="flex flex-col justify-center items-center pb-32">
            {error && (
                <Typography
                    className="text-error text-xl text-white tracking-wider mb-4 text-primary"
                    variant="h2"
                >
                    Error: {error}
                </Typography>
            )}
            <Slide
                direction="up"
                in={showSingleAlbum}
                mountOnEnter
                unmountOnExit
            >
                <Box className="flex w-full">
                    <SingleAlbumPage
                        id={currentId}
                        title={title}
                        setShowSingleAlbum={setShowSingleAlbum}
                        setCurrentId={setCurrentId}
                        setTrackIndex={setTrackIndex}
                        trackIndex={trackIndex}
                    />
                </Box>
            </Slide>
            <Slide
                direction="up"
                in={!showSingleAlbum}
                mountOnEnter
                unmountOnExit
            >
                <Box>
                    <AlbumList
                        id={currentId}
                        setShowSingleAlbum={setShowSingleAlbum}
                        setCurrentId={setCurrentId}
                    />
                </Box>
            </Slide>
            <TrackPlayer
                track={selectedTrack}
                onClickNext={onClickNext}
                onClickPrevious={onClickPrevious}
                onEnded={onClickNext}
                showSingleTrack={true}
            />
        </Box>
    );
};

export default React.memo(AlbumsContainer);
