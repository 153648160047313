import React, { useCallback, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import FileBase from 'react-file-base64';
import useStyles from './styles';
import { generateGif, getGenerateStatus } from '../../api';
import { useSelector } from 'react-redux';
import { getIsValidFileType } from '../../utils/posts';
import { Link } from 'react-router-dom';

const GifConversionContainer = (props) => {
    // style
    const classes = useStyles();

    const [trackData, setTrackData] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const user = useSelector((state) => state.auth.authData);
    const src = `https://ill-defined-photos.s3.us-east-2.amazonaws.com/${user?.uid}-temp.gif`;

    const pollJobStatus = useCallback(async (id) => {
        const timer = setInterval(async () => {
            const response = await getGenerateStatus(id);

            const isCompleted = response?.data?.state === 'completed';
            if (isCompleted) {
                clearInterval(timer);
                setSuccess(true);
                setIsFetching(false);
                return;
            }

            const didFail = !!response?.data?.failedReason;
            if (didFail) {
                clearInterval(timer);
                setError('Error creating GIF. Please try again later.');
                setSuccess(false);
                setIsFetching(false);
                return;
            }
        }, 500);
    }, []);

    const onSubmit = useCallback(
        async (e) => {
            e?.preventDefault();

            const isValid = getIsValidFileType(trackData);
            if (!isValid) {
                setError('Invalid File Type.  File must be type: mp3');
                return;
            }

            setIsFetching(true);
            setError(false);

            try {
                const response = await generateGif({ trackData });
                pollJobStatus(response?.data?.id);
            } catch (error) {
                setError('Error creating GIF. Please try again later.');
                setSuccess(false);
                setIsFetching(false);
            }
        },
        [trackData, pollJobStatus]
    );

    const downloadGif = () => {
        const a = document.createElement('a');
        a.href = src;
        a.target = '_blank';
        a.setAttribute('download', prompt);
        a.click();
    };

    // render

    if (!user) {
        return (
            <Box className="flex items-center justify-center flex-col">
                <Typography align="center" color="secondary" variant="p" mb={2}>
                    Welcome to our MP3 to GIF Converter! Please sign in or
                    create a free account. It's quick, easy, and absolutely
                    free!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/auth/audioToGif"
                >
                    Login / Create Account
                </Button>
            </Box>
        );
    }

    return (
        <Box className="flex items-center justify-center">
            <form
                autoComplete="off"
                noValidate
                className="flex pt-2 px-4 flex-col items-center justify-center border-2 rounded-2xl border-white border-solid"
                onSubmit={onSubmit}
                style={{
                    boxShadow: '4px 4px 10px #B0B3B890'
                }}
            >
                {error && (
                    <Typography variant="p" color="primary" mb={2}>
                        {error}
                    </Typography>
                )}
                <Typography
                    className="text-xl text-white tracking-wider mb-4 text-secondary"
                    variant="h6"
                >
                    MP3 To GIF Creator
                </Typography>
                <Box className={classes.fileInput}>
                    <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) => setTrackData(base64)}
                    />
                </Box>
                <Button
                    className="mb-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth
                >
                    Submit
                </Button>
                {isFetching && <CircularProgress sx={{ mt: 2, mb: 2 }} />}
                {success && !isFetching && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 2,
                            width: '100%'
                        }}
                    >
                        <Typography variant="p" color="secondary" mt={2} mb={1}>
                            Result
                        </Typography>
                        <Box
                            component="img"
                            sx={{
                                height: '25vw',
                                width: '25vw',
                                borderRadius: 4
                            }}
                            alt="gifs"
                            src={src}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadGif}
                            size="small"
                            sx={{ width: '50%', mt: 2 }}
                        >
                            Download
                        </Button>
                    </Box>
                )}
            </form>
        </Box>
    );
};

export default React.memo(GifConversionContainer);
