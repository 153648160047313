import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton
} from 'react-admin';

export const PostList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="title" />
            <TextField source="creator" />
            <EditButton />
        </Datagrid>
    </List>
);
