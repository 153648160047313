import React, { useState, useCallback } from 'react';
import useStyles from './styles';
import { TextField, Button, Typography, Box } from '@mui/material';
// utils
import FileBase from 'react-file-base64';

const isComplete = (album) => {
    return album?.title && album?.creator && album?.albumArtwork;
};

const AlbumInfoInput = React.memo((props) => {
    // data
    const { albumInfo, setAlbumInfo, toNextStep } = props;

    // state
    const [albumData, setAlbumData] = useState(albumInfo);
    const hasAllInfo = isComplete(albumData);

    // style
    const classes = useStyles();

    // actions
    const finishAblumInfo = useCallback(() => {
        setAlbumInfo(albumData);
        toNextStep();
    }, [albumData, setAlbumInfo, toNextStep]);

    // render
    return (
        <Box>
            <Typography variant="h3" className="text-white text-lg inline mr-2">
                Album Info
            </Typography>
            <TextField
                name="title"
                variant="filled"
                label="Title"
                fullWidth
                value={albumData?.title}
                className={classes.textInput}
                onChange={(e) =>
                    setAlbumData({
                        ...albumData,
                        title: e.target.value
                    })
                }
            />
            <TextField
                name="creator"
                variant="filled"
                label="Creator"
                fullWidth
                value={albumData?.creator}
                className={classes.textInput}
                sx={{ mb: 2 }}
                onChange={(e) =>
                    setAlbumData({
                        ...albumData,
                        creator: e.target.value
                    })
                }
            />
            <div className={classes.fileInput}>
                <Typography variant="p" className="text-white text-lg">
                    Album Artwork
                </Typography>
                <FileBase
                    type="file"
                    multiple={false}
                    onDone={({ base64 }) =>
                        setAlbumData({
                            ...albumData,
                            albumArtwork: base64
                        })
                    }
                />
            </div>
            <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={finishAblumInfo}
                disabled={!hasAllInfo}
                fullWidth
            >
                Next
            </Button>
        </Box>
    );
});

export default AlbumInfoInput;
