import { initializeApp } from 'firebase/app';
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut as firebaseSignOut
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyD-1KLIkqpGcuN9PTJ75zp6RkLqSIV0z3M',
    authDomain: 'ill-defined-cd48e.firebaseapp.com',
    projectId: 'ill-defined-cd48e',
    storageBucket: 'ill-defined-cd48e.appspot.com',
    messagingSenderId: '166794291788',
    appId: '1:166794291788:web:f01def97bc94e3c495a2e9',
    measurementId: 'G-8KXVVKT77W'
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const signUp = async (formData) => {
    const { email, password } = formData;
    try {
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        return userCredential;
    } catch (error) {
        return {
            error
        };
    }
};

export const signIn = async (formData) => {
    const { email, password } = formData;
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        return userCredential;
    } catch (error) {
        return {
            error
        };
    }
};

export const signOut = async () => {
    try {
        await firebaseSignOut(auth);
        return true;
    } catch (error) {
        return false;
    }
};

export const getIdToken = async () => {
    try {
        const token = await auth.currentUser.getIdToken();
        return token;
    } catch (error) {
        return false;
    }
};

const defaultExport = {
    signUp,
    signIn,
    getIdToken
};

export default defaultExport;
