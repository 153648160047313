import React, { useState, useCallback } from 'react';
import {
    Avatar,
    Button,
    Paper,
    Grid,
    Typography,
    Container,
    CircularProgress
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Input from './Input';
import { useNavigate, useParams } from 'react-router-dom';
import { signin, signup, deleteUserAction } from '../../actions/auth';

import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';

const initialState = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
};

const Auth = () => {
    // config
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // route
    const redirectRoute = useParams()?.route;

    // redux
    const errorMessage = useSelector((state) => state.auth.error);
    const isLoading = useSelector((state) => state.auth.loading);
    const user = useSelector((state) => state.auth.authData);

    // state
    const [showPassword, setShowPassword] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [formData, setFormData] = useState(initialState);

    // actions
    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (isSignup) {
                dispatch(signup(formData, navigate, redirectRoute));
            } else {
                dispatch(signin(formData, navigate, redirectRoute));
            }
        },
        [dispatch, formData, isSignup, navigate, redirectRoute]
    );

    const handleChange = useCallback(
        (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        },
        [formData]
    );

    const handleShowPassword = () =>
        setShowPassword((prevShowPassword) => !prevShowPassword);

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
        setShowPassword(false);
    };

    const deleteUser = useCallback(() => {
        const id = user?.result?._id;
        dispatch(deleteUserAction(id, navigate));
    }, [user, dispatch, navigate]);

    // render
    const renderFormBody = useCallback(() => {
        if (!!user) {
            return (
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2} justifyContent="center">
                        <Typography variant="h5" align="center">
                            Click below to delete your account.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={deleteUser}
                            sx={{ marginTop: 2 }}
                        >
                            Delete Account
                        </Button>
                    </Grid>
                </form>
            );
        } else {
            return (
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {isSignup && (
                            <>
                                <Input
                                    name="firstName"
                                    label="First Name"
                                    handleChange={handleChange}
                                    autoFocus
                                    half
                                />
                                <Input
                                    name="lastName"
                                    label="Last Name"
                                    handleChange={handleChange}
                                    half
                                />
                                <Input
                                    name="username"
                                    label="Username"
                                    handleChange={handleChange}
                                />
                            </>
                        )}
                        <Input
                            name="email"
                            label="Email Address"
                            handleChange={handleChange}
                            type="email"
                        />
                        <Input
                            name="password"
                            label="Password"
                            handleChange={handleChange}
                            type={showPassword ? 'text' : 'password'}
                            handleShowPassword={handleShowPassword}
                        />
                        {isSignup && (
                            <Input
                                name="confirmPassword"
                                label="Repeat Password"
                                handleChange={handleChange}
                                type="password"
                            />
                        )}
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {isSignup ? 'Sign Up' : 'Sign In'}
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button onClick={switchMode}>
                                {isSignup
                                    ? 'Already have an account? Sign In'
                                    : "Don't have an account? Sign Up Here"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            );
        }
    }, [
        user,
        classes.form,
        classes.submit,
        handleSubmit,
        deleteUser,
        isSignup,
        handleChange,
        showPassword
    ]);

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon color="primary" />
                </Avatar>
                <Typography variant="h5" align="center">
                    {!!user ? null : isSignup ? 'Sign Up' : 'Sign In'}
                </Typography>
                <Typography variant="h6" align="center" color="primary">
                    {errorMessage}
                </Typography>
                {isLoading ? <CircularProgress /> : renderFormBody()}
            </Paper>
        </Container>
    );
};

export default Auth;
