import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    audioPlayerWrap: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 2
    },
    media: {
        [theme.breakpoints.down('sm')]: {
            height: '65vw',
            width: '65vw'
        },
        height: 350,
        width: 350,
        backgroundBlendMode: 'darken',
        backgroundSize: 'cover',
        margin: 4,
        borderRadius: '10px',
        boxShadow: '8px 8px 18px #B0B3B890'
    },
    border: {
        border: 'solid'
    },
    fullHeightCard: {
        height: '100%'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#000000',
        paddingBottom: 100
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'white'
    },
    overlay2: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        color: 'white'
    },
    grid: {
        display: 'flex'
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px'
    },
    title: {
        marginTop: 20
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    imageTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    playPauseIcon: {
        color: '#ffffff',
        fontSize: 30
    }
}));
