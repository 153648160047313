import React, { useCallback, useState } from 'react';
import useStyles from './styles';
import MultiStepContainer from './MultiStepContainer';

// redux
import { useDispatch } from 'react-redux';
import { createAlbum } from '../../../../actions/albums';

// config
const BLANK_TRACK = {
    title: '',
    audioFile: '',
    isNew: true,
    hasAlbum: true
};

const BLANK_ALBUM = {
    title: '',
    creator: '',
    albumArtwork: ''
};

const SingleTrackForm = React.memo(({ currentTabIndex, index }) => {
    const dispatch = useDispatch();
    // data
    const [tracks, setTracks] = useState([BLANK_TRACK]);
    const [albumInfo, setAlbumInfo] = useState(BLANK_ALBUM);

    // style
    const classes = useStyles();

    // actions
    const handleSubmit = (e) => {
        e?.preventDefault?.();
        dispatch(createAlbum({ albumInfo, tracks }));
    };

    const addTrack = useCallback(() => {
        setTracks([...tracks, BLANK_TRACK]);
    }, [tracks]);

    const updateTracks = useCallback(
        (newTrack, index) => {
            let newTracks = [...tracks];
            newTracks[index] = newTrack;
            setTracks(newTracks);
        },
        [tracks]
    );

    // render
    if (currentTabIndex !== index) {
        return null;
    }

    return (
        <form
            autoComplete="off"
            noValidate
            className={`${classes.root} ${classes.form}`}
            onSubmit={handleSubmit}
        >
            <MultiStepContainer
                addTrack={addTrack}
                updateTracks={updateTracks}
                tracks={tracks}
                albumInfo={albumInfo}
                setAlbumInfo={setAlbumInfo}
                onSubmit={handleSubmit}
            />
        </form>
    );
});

export default SingleTrackForm;
