import React, { useState, useCallback } from 'react';
import useStyles from './styles';
import {
    TextField,
    Button,
    Typography,
    Box,
    Modal,
    CircularProgress
} from '@mui/material';

// api
import * as api from '../../../../api';

// config
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flex: 1,
    bgcolor: 'black',
    boxShadow: 24,
    px: 3,
    py: 2,
    width: '60vw',
    border: '2px solid #ffffff',
    borderRadius: '15px'
};
const ERROR_MSG =
    'Invalid prompt.  Please try again or choose a different prompt.';

const AiImagePromptModal = React.memo(({ open, onClose }) => {
    // data
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const classes = useStyles();

    // actions
    const generateImages = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.generateAiImage({ prompt });
            setResult(response?.data?.data[0]?.url);
        } catch (error) {
            setError(ERROR_MSG);
            setResult(null);
        }

        setLoading(false);
    }, [prompt]);

    const downloadPic = () => {
        const a = document.createElement('a');
        a.href = result;
        a.target = '_blank';
        a.setAttribute('download', prompt);
        a.click();
    };

    // render
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {error && (
                        <Typography variant="p" color="primary" mb={1}>
                            {error}
                        </Typography>
                    )}
                    <TextField
                        name="prompt"
                        variant="filled"
                        label="Image Description"
                        fullWidth
                        value={prompt}
                        className={classes.textInput}
                        sx={{ mr: 1, mb: 1 }}
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generateImages}
                        disabled={loading}
                    >
                        Generate
                    </Button>
                    {loading && <CircularProgress sx={{ mt: 2 }} />}
                    {result && !loading && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="p"
                                color="secondary"
                                mt={2}
                                mb={1}
                            >
                                Result
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: '25vw',
                                    width: '25vw',
                                    borderRadius: 4
                                }}
                                alt="AI image result"
                                src={result}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={downloadPic}
                                size="small"
                                sx={{ width: '50%', mt: 2 }}
                            >
                                Download
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
});

export default AiImagePromptModal;
