import React from 'react';
import { AppBar, Typography, IconButton } from '@mui/material';
import useStyles from './styles';
import { Link, useLocation } from 'react-router-dom';

// icons
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = (props) => {
    // data
    const { toggleDrawer } = props;
    const classes = useStyles();
    const location = useLocation();

    // render
    if (location?.pathname?.includes('admin')) {
        return null;
    }

    return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <div className={classes.brandContainer}>
                <IconButton
                    color="secondary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component={Link}
                    to="/"
                    className={classes.heading}
                    variant="h1"
                    align="center"
                >
                    ILL DEFINED
                </Typography>
            </div>
        </AppBar>
    );
};

export default Navbar;
