import React, { useState, useEffect } from 'react';
import { Container, Grow, Grid } from '@mui/material';
import SingleTrack from '../Track/SingleTrack';
import TrackPlayer from '../Track/TrackPlayer';
import Posts from '../Posts/Posts';
import Slide from '@mui/material/Slide';

// utils
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setPost } from '../../actions/posts';
import { useParams } from 'react-router-dom';

const Home = () => {
    // state
    const [currentId, setCurrentId] = useState(null);
    const [showSingleTrack, setShowSingleTrack] = useState(false);
    const [title, setTitle] = useState(useParams()?.title);

    // hooks
    const classes = useStyles();
    const dispatch = useDispatch();

    // redux
    const user = useSelector((state) => state.auth.authData);

    // actions
    useEffect(() => {
        if (!!title) {
            setShowSingleTrack(true);
        }
    }, []); // eslint-disable-line

    const onPlayStop = (id) => {
        if (id === currentId) {
            setCurrentId(null);
            return dispatch(setPost({}));
        }
        dispatch(setPost({}));
        setCurrentId(id);
        setShowSingleTrack(true);
    };

    return (
        <Grow in>
            <Container className={classes.mainContainer} maxWidth="md">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    height="100%"
                    spacing={3}
                >
                    <Slide
                        direction="up"
                        in={showSingleTrack}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Grid justifyContent="center" item xs={12}>
                            <SingleTrack
                                id={currentId}
                                title={title}
                                setShowSingleTrack={setShowSingleTrack}
                                setTitle={setTitle}
                                setCurrentId={setCurrentId}
                            />
                        </Grid>
                    </Slide>
                    <Slide
                        direction="up"
                        in={!showSingleTrack}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Grid justifyContent="center" item xs={12}>
                            <Posts
                                onPlayStop={onPlayStop}
                                currentId={currentId}
                                user={user}
                            />
                        </Grid>
                    </Slide>
                    <TrackPlayer
                        showSingleTrack={showSingleTrack}
                        setShowSingleTrack={setShowSingleTrack}
                    />
                </Grid>
            </Container>
        </Grow>
    );
};

export default Home;
