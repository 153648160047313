import * as api from '../api';
import {
    FETCH_ALL,
    FETCH_SINGLE,
    CREATE,
    UPDATE,
    DELETE,
    LIKE,
    CREATE_REQUEST,
    CREATE_ERROR,
    SET_POST,
    FETCH_REQUEST,
    UPDATE_REQUEST,
    FETCH_ERROR,
    UPDATE_ERROR
} from '../constants/actionTypes';

export const getPosts = (page, cb) => async (dispatch) => {
    try {
        const { data } = await api.fetchPosts(page);
        dispatch({ type: FETCH_ALL, payload: data });
        cb?.();
    } catch (error) {
        console.log(error.message);
    }
};

export const getPost = (id) => async (dispatch) => {
    dispatch({ type: FETCH_REQUEST });

    try {
        const { data } = await api.fetchPost(id);
        dispatch({ type: FETCH_SINGLE, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
    }
};

export const getPostByTitle = (title, cb) => async (dispatch) => {
    try {
        const { data } = await api.fetchPostByTitle(title);
        dispatch({ type: FETCH_SINGLE, payload: data });
        cb?.();
    } catch (error) {
        console.log(error.message);
    }
};

export const setPost = (post) => ({
    type: SET_POST,
    payload: post
});

export const createPost = (post) => async (dispatch) => {
    dispatch({ type: CREATE_REQUEST });

    try {
        const { data } = await api.createPost(post);
        dispatch({ type: CREATE, payload: data });
    } catch (error) {
        dispatch({ type: CREATE_ERROR, payload: error.response.data.message });
    }
};

export const updatePost = (id, post) => async (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });

    try {
        const { data } = await api.updatePost(id, post);

        dispatch({ type: UPDATE, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_ERROR, payload: error.response.data.message });
    }
};

export const deletePost = (id) => async (dispatch) => {
    try {
        await api.deletePost(id);

        dispatch({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error);
    }
};

export const likePost = (id) => async (dispatch) => {
    try {
        const { data } = await api.likePost(id);

        dispatch({ type: LIKE, payload: data });
    } catch (error) {
        console.log(error);
    }
};

export const generateGif = () => async (dispatch) => {
    try {
        const res = await api.generateGif();

        console.log(res);
    } catch (error) {
        console.log(error);
    }
};
