import axios from 'axios';

// util
import { getIdToken } from '../utils/auth';

const url = 'https://ill-defined.herokuapp.com';
// const url = 'https://vast-shelf-05688-76f853fe71eb.herokuapp.com';
// const url = 'http://localhost:5000';

const API = axios.create({ baseURL: url });
API.interceptors.request.use(async (req) => {
    const token = await getIdToken();

    if (!!token) {
        req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
});

// posts
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPostByTitle = (title) => API.get(`/posts/single/${title}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const updatePost = (id, updatedPost) =>
    API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

// users
export const signIn = (formData) => API.post('/users/signin', formData);
export const signUp = (formData) => API.post('/users/signup', formData);
export const deleteUser = (id) => API.delete(`/users/${id}`);

// albums
export const fetchAlbums = (page) => API.get(`/albums?page=${page}`);
export const createAlbum = (data) => API.post('/albums', data);
export const fetchAlbum = (id) => API.get(`/albums/${id}`);
export const fetchAlbumByTitle = (title) => API.get(`/albums/single/${title}`);

// ai image
export const generateAiImage = (data) => API.post('/ai_image/generate', data);

// audio to visual
export const generateGif = (data) =>
    API.post('/audio-to-visual/generate', data);
export const getGenerateStatus = (id) =>
    API.get(`/audio-to-visual/status/${id}`);
