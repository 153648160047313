import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';
import { Button, Box } from '@mui/material';

// assets
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// player import
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

// redux
import { useSelector } from 'react-redux';

const SingleTrack = React.memo((props) => {
    const {
        setShowSingleTrack,
        showSingleTrack,
        track: propsTrack,
        onClickNext,
        onClickPrevious,
        onEnded
    } = props;

    // state
    const [audioFile, setAudioFile] = useState('');

    // hooks
    const classes = useStyles();
    const selectorTrack = useSelector((state) => state.posts.post);

    // data
    const track = propsTrack ?? selectorTrack;

    // actions
    const handleSetShowSingleTrack = useCallback(() => {
        setShowSingleTrack?.(true);
    }, [setShowSingleTrack]);

    useEffect(() => {
        setAudioFile(track?.audioFile || '');
    }, [track]);

    // render
    const button = useMemo(() => {
        if (!audioFile || !!showSingleTrack) {
            return null;
        }

        return (
            <Button
                onClick={handleSetShowSingleTrack}
                variant="text"
                color="primary"
                sx={{ flexDirection: 'column' }}
            >
                <KeyboardArrowUpIcon
                    sx={{ mb: -1 }}
                    fontSize="large"
                    color="primary"
                />
            </Button>
        );
    }, [audioFile, showSingleTrack, handleSetShowSingleTrack]);

    return (
        <Box className={classes.audioPlayerWrap}>
            {button}
            <AudioPlayer
                src={audioFile}
                onClickNext={onClickNext}
                onClickPrevious={onClickPrevious}
                showJumpControls={false}
                showSkipControls
                onEnded={onEnded}
            />
        </Box>
    );
});
export default SingleTrack;
