import React, { useCallback } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';

// assets
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EqualizerIcon from '@mui/icons-material/Equalizer';

// connect
import { useSelector } from 'react-redux';

// helpers
const tracksArrCompare = (oldArr, newArr) => {
    const isDifferent = newArr.some((track, index) => {
        if (!track?.audioFile) {
            return false;
        }

        return !oldArr?.[index].hasOwnProperty('audioFile');
    });

    return isDifferent;
};

const TrackList = (props) => {
    const { setTrackIndex, trackIndex } = props;

    // connect
    const tracks = useSelector(
        (state) => state?.albums?.album?.tracks,
        tracksArrCompare
    );

    // actions
    const onPlayStop = useCallback(
        (index) => {
            setTrackIndex(index);
        },
        [setTrackIndex]
    );

    // render
    if (!tracks) {
        return null;
    }

    return tracks?.map((track, index) => {
        const isLastTrack = index === tracks?.length - 1;
        const isPlaying = trackIndex === index;

        if (!track?.audioFile) {
            return (
                <Box
                    key={track?._id}
                    className={`flex flex-row justify-between items-center w-80 border-solid border-t-2 border-b-0 border-tertiary border-x-0 h-10 ${
                        isLastTrack && 'border-b-2'
                    }`}
                >
                    <Typography className="text-center text-medium text-white tracking-wider">
                        Loading...
                    </Typography>
                    <CircularProgress size={20} />
                </Box>
            );
        }

        return (
            <Box
                key={track?._id}
                className={`flex flex-row justify-between items-center w-80 border-solid border-t-2 border-b-0 border-tertiary border-x-0 h-10 ${
                    isLastTrack && 'border-b-2'
                }`}
            >
                <Typography className="text-center text-medium text-white tracking-wider">
                    {track?.title}
                </Typography>

                {!isPlaying ? (
                    <IconButton
                        color="secondary"
                        aria-label="play track"
                        onClick={() => onPlayStop(index)}
                    >
                        <PlayCircleOutlineIcon fontSize="medium" />
                    </IconButton>
                ) : (
                    <IconButton color="secondary" aria-label="play track">
                        <EqualizerIcon fontSize="medium" />
                    </IconButton>
                )}
            </Box>
        );
    });
};

export default React.memo(TrackList);
