import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    fileInputWrap: {
        width: '97%',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    fileInput: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        '& input': {
            color: '#ffffff'
        }
    },
    buttonSubmit: {
        marginBottom: 10
    },
    textInput: {
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        marginTop: 16
    },
    finishedTrackBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '10px',
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
        backgroundColor: '#000000',
        border: '1px solid #ffffff',
        boxShadow: '4px 4px 10px #B0B3B890',
        marginBottom: 16
    }
}));
