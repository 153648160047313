import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#BA0F30'
        },
        secondary: {
            main: '#ffffff'
        },
        tertiary: {
            main: '#BEBEBE'
        }
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#000000'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: '#000',
                        backgroundColor: '#BEBEBE'
                    },
                    textTransform: 'none'
                }
            }
        }
    }
});
