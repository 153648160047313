import React, { useCallback } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';

const AlbumItem = (props) => {
    const { album, setShowSingleAlbum, setCurrentId, innerRef } = props;
    const albumId = album?._id;

    // actions
    const onClick = useCallback(() => {
        setCurrentId(albumId);
        setShowSingleAlbum(true);
    }, [albumId, setCurrentId, setShowSingleAlbum]);

    return (
        <Button onClick={onClick}>
            <Grid
                key={albumId}
                item
                s={6}
                className="flex justify-center items-center flex-col"
                ref={innerRef}
            >
                <Box
                    className="flex justify-center items-center rounded-lg"
                    style={{
                        backgroundImage: `url(${album?.albumArtwork})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: 250,
                        width: 250
                    }}
                />
                <Typography className="text-center text-lg text-white tracking-wider mt-2">
                    {album?.title}
                </Typography>
            </Grid>
        </Button>
    );
};

export default React.memo(AlbumItem);
