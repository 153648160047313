const VALID_FILE_TYPES = ['mp3', 'mpeg'];

export const formatTitleForApi = (title) => {
    const wordMap = title?.split?.('-');
    const capitalMap = wordMap?.map?.(
        (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
    );
    return capitalMap?.join(' ');
};

export const getIsValidFileType = (base64) => {
    if (!base64) {
        return false;
    }

    const type = base64.split(';')[0].split('/')[1];
    return VALID_FILE_TYPES.includes(type);
};
