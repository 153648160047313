import zIndex from '@mui/material/styles/zIndex';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    appBar: {
        borderRadius: 15,
        backgroundColor: '#000000',
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    heading: {
        color: '#ffffff'
    },
    image: {
        marginLeft: '15px'
    },
    mainContainer: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
