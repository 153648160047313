import * as api from '../api';
import {
    AUTH,
    AUTH_ERROR,
    DELETE_USER,
    AUTH_REQUEST
} from '../constants/actionTypes';
import firebaseUtils from '../utils/auth';

export const signin = (formData, navigate, route) => async (dispatch) => {
    dispatch({ type: AUTH_REQUEST });

    try {
        const firebaseCreds = await firebaseUtils.signIn(formData);
        if (firebaseCreds?.error) {
            return dispatch({
                type: AUTH_ERROR,
                error: firebaseCreds?.error?.message
            });
        }

        const signInData = {
            ...formData,
            token: firebaseCreds?.user?.accessToken
        };

        const res = await api.signIn(signInData);

        dispatch({ type: AUTH, data: res?.data?.result });

        navigate(`/${!route ? '' : route}`);
    } catch (error) {
        dispatch({ type: AUTH_ERROR, error: error.response.data.message });
    }
};

export const signup = (formData, navigate, route) => async (dispatch) => {
    dispatch({ type: AUTH_REQUEST });

    try {
        // create firebase account
        const firebaseCreds = await firebaseUtils.signUp(formData);
        if (firebaseCreds?.error) {
            return dispatch({
                type: AUTH_ERROR,
                error: firebaseCreds?.error?.message
            });
        }
        const signUpData = {
            ...formData,
            uid: firebaseCreds?.user?.uid
        };

        delete signUpData?.password;
        delete signUpData?.confirmPassword;

        const res = await api.signUp(signUpData);

        dispatch({ type: AUTH, data: res?.data?.result });

        navigate(`/${!route ? '' : route}`);
    } catch (error) {
        dispatch({ type: AUTH_ERROR, error: error.response.data.message });
    }
};

export const setUser = () => async (dispatch) => {
    const token = await firebaseUtils.getIdToken();

    try {
        const res = await api.signIn({ token });
        dispatch({ type: AUTH, data: res?.data?.result });
    } catch (error) {
        dispatch({ type: AUTH_ERROR, error: error });
    }
};

export const deleteUserAction = (id, navigate) => async (dispatch) => {
    try {
        await api.deleteUser(id);
        dispatch({ type: DELETE_USER });
        navigate('/');
    } catch (error) {
        dispatch({ type: AUTH_ERROR, error: error.response.data.message });
    }
};
