import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import AlbumItem from './AlbumItem';

// utils
import { isMobile } from 'react-device-detect';

// connect
import { useDispatch, useSelector } from 'react-redux';
import { getAlbums } from '../../actions/albums';

// hooks
import { useInView } from 'react-intersection-observer';

const AlbumList = (props) => {
    const { setShowSingleAlbum, setCurrentId } = props;
    const dispatch = useDispatch();

    // state
    const [isFetching, setIsFetching] = useState(false);

    // redux
    const albums = useSelector((state) => {
        return state.albums.albums;
    });
    const nextPage = useSelector((state) => state.albums?.nextPage);

    // actions
    const fetchData = useCallback(
        (page) => {
            if (!page || isFetching) {
                return;
            }

            setIsFetching(true);
            dispatch(getAlbums(page, () => setIsFetching(false)));
        },
        [dispatch, isFetching]
    );

    const onFeedEndReached = useCallback(() => {
        if (!nextPage) {
            return;
        }

        fetchData(nextPage);
    }, [nextPage, fetchData]);

    // intersection observer
    const onChange = useCallback(
        (inView) => {
            if (!!inView && !!nextPage) {
                onFeedEndReached();
            }
        },
        [onFeedEndReached, nextPage]
    );

    const { ref: inViewRef } = useInView({
        threshold: 0.5,
        onChange
    });

    const setRefs = useCallback(
        (node) => {
            inViewRef(node);
        },
        [inViewRef]
    );

    useEffect(() => {
        !albums?.length && fetchData(1);
    }, [albums]); // eslint-disable-line

    // render
    if (!albums?.length) {
        return (
            <Box className="flex flex-col justify-center items-center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid
            container
            spacing={isMobile ? 0 : 2}
            className="flex justify-center items-center"
        >
            {albums?.map((album, index) => {
                const isLastItem = index === albums?.length - 1;

                if (!isLastItem) {
                    return (
                        <AlbumItem
                            key={album?._id}
                            album={album}
                            setShowSingleAlbum={setShowSingleAlbum}
                            setCurrentId={setCurrentId}
                        />
                    );
                }

                return (
                    <AlbumItem
                        key={album?._id}
                        album={album}
                        setShowSingleAlbum={setShowSingleAlbum}
                        setCurrentId={setCurrentId}
                        innerRef={setRefs}
                    />
                );
            })}
            {isFetching && (
                <Box className="flex w-full justify-center items-center mt-4">
                    <CircularProgress />
                </Box>
            )}
        </Grid>
    );
};

export default React.memo(AlbumList);
