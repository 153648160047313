import React, { useState, useMemo } from 'react';
import { Box } from '@mui/material';
import EditTrackRow from './EditTrackRow';
import FinishedTrack from './FinishedTrack';

const MultiTrackInput = React.memo((props) => {
    // data
    const { track, index, updateTracks } = props;

    // state
    const [isEditing, setIsEditing] = useState(!!track?.isNew);

    // render
    const row = useMemo(() => {
        if (isEditing) {
            return (
                <EditTrackRow
                    track={track}
                    index={index}
                    updateTracks={updateTracks}
                    onDone={() => setIsEditing(false)}
                />
            );
        }

        return (
            <FinishedTrack track={track} onEdit={() => setIsEditing(true)} />
        );
    }, [isEditing, track, index, updateTracks]);

    return <Box>{row}</Box>;
});

export default MultiTrackInput;
