import { Admin, Resource, EditGuesser } from 'react-admin';
import { UserList } from './components/users';
import { UserEdit } from './components/UserEdit';
import { PostList } from './components/posts';
import dataProvider from './dataProvider';

// utils
import { useSelector } from 'react-redux';

const AdminApp = () => {
    // connect
    const authUser = useSelector((state) => state.auth.authData);
    const isAdmin = authUser?.isAdmin;

    if (!isAdmin) {
        return null;
    }

    return (
        <Admin basename="/admin" dataProvider={dataProvider}>
            <Resource name="posts" list={PostList} edit={EditGuesser} />
            <Resource name="users" list={UserList} edit={UserEdit} />
        </Admin>
    );
};

export default AdminApp;
