import React, { useCallback, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    TextField
} from '@mui/material';
import useStyles from './styles';
import { generateAiImage } from '../../api';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ERROR_MSG =
    'Invalid prompt.  Please try again or choose a different prompt.';

const AiImageGenContainer = (props) => {
    // style
    const classes = useStyles();

    // selector
    const user = useSelector((state) => state.auth.authData);

    const [prompt, setPrompt] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);

    const onSubmit = useCallback(
        async (e) => {
            e?.preventDefault();

            setIsFetching(true);
            setError(null);

            try {
                const response = await generateAiImage({ prompt });
                setResult(response?.data?.data[0]?.url);
            } catch (error) {
                setError(ERROR_MSG);
                setResult(null);
            }

            setIsFetching(false);
        },
        [prompt]
    );

    const downloadPic = () => {
        const a = document.createElement('a');
        a.href = result;
        a.target = '_blank';
        a.setAttribute('download', prompt);
        a.click();
    };

    // render

    if (!user) {
        return (
            <Box className="flex items-center justify-center flex-col">
                <Typography align="center" color="secondary" variant="p" mb={2}>
                    Welcome to our custom AI Image Generator! Please sign in or
                    create a free account. It's quick, easy, and absolutely
                    free!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/auth/aiImageGen"
                >
                    Login / Create Account
                </Button>
            </Box>
        );
    }

    return (
        <Box className="flex items-center justify-center">
            <form
                autoComplete="off"
                noValidate
                className="flex pt-2 px-4 flex-col items-center justify-center border-2 rounded-2xl border-white border-solid w-full"
                onSubmit={onSubmit}
                style={{
                    boxShadow: '4px 4px 10px #B0B3B890'
                }}
            >
                {error && (
                    <Typography variant="p" color="primary" mb={2}>
                        {error}
                    </Typography>
                )}
                <Typography
                    className="text-xl text-white tracking-wider mb-4 text-secondary"
                    variant="h6"
                >
                    AI Image Generator
                </Typography>
                <TextField
                    name="prompt"
                    variant="filled"
                    label="Image Description"
                    fullWidth
                    value={prompt}
                    className={classes.textInput}
                    sx={{ mb: 1 }}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <Button
                    className="mb-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth
                >
                    Submit
                </Button>
                {isFetching && <CircularProgress sx={{ mt: 2, mb: 2 }} />}
                {result && !isFetching && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 2,
                            width: '100%'
                        }}
                    >
                        <Typography variant="p" color="secondary" mt={2} mb={1}>
                            Result
                        </Typography>
                        <Box
                            component="img"
                            sx={{
                                height: '25vw',
                                width: '25vw',
                                borderRadius: 4
                            }}
                            alt="AI image result"
                            src={result}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadPic}
                            size="small"
                            sx={{ width: '50%', mt: 2 }}
                        >
                            Download
                        </Button>
                    </Box>
                )}
            </form>
        </Box>
    );
};

export default React.memo(AiImageGenContainer);
