import React, { useCallback } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ToolsListItem = (props) => {
    const { tool } = props;
    const navigate = useNavigate();
    // render

    const onPress = useCallback(() => {
        navigate(tool?.route);
    }, [navigate, tool?.route]);

    return (
        <Button onClick={onPress}>
            <Grid
                key={tool?.key}
                item
                s={6}
                className="flex justify-center items-center flex-col"
            >
                <Box
                    className="flex flex-row justify-center items-center rounded-lg"
                    style={{
                        borderRadius: '15px',
                        padding: 8,
                        backgroundColor: '#000000',
                        border: '1px solid #ffffff',
                        boxShadow: '4px 4px 10px #B0B3B890'
                    }}
                >
                    {tool?.icon}
                    <Typography className="text-center text-lg text-white ml-2">
                        {tool?.display}
                    </Typography>
                </Box>
            </Grid>
        </Button>
    );
};

export default React.memo(ToolsListItem);
