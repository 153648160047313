import React, { useState, useCallback } from 'react';
import useStyles from './styles';
import { TextField, Button, Typography, Box } from '@mui/material';

// utils
import FileBase from 'react-file-base64';
import { getIsValidFileType } from '../../../../utils/posts';

const isComplete = (track) => {
    return track?.title && track?.audioFile;
};

const AddTrackRow = React.memo((props) => {
    // data
    const { track, index, updateTracks, onDone } = props;

    // state
    const [trackData, setTrackData] = useState(track);
    const hasAllInfo = isComplete(trackData);
    const [error, setError] = useState(null);

    // style
    const classes = useStyles();

    // actions
    const finishTrack = useCallback(() => {
        const isValid = getIsValidFileType(trackData?.audioFile);
        if (!isValid) {
            setError('Invalid File Type.  File must be type: mp3');
            return;
        }

        !!trackData?.isNew && delete trackData.isNew;
        updateTracks(trackData, index);
        setError(null);
        onDone();
    }, [trackData, index, updateTracks, onDone]);

    // render
    return (
        <Box>
            {error && (
                <Typography textAlign="center" color="error.main" variant="h6">
                    {error}
                </Typography>
            )}
            <Typography variant="h3" className="text-white text-lg inline mr-2">
                Track {index + 1}
            </Typography>
            <TextField
                name="title"
                variant="filled"
                label="Title"
                fullWidth
                value={trackData.title}
                className={classes.textInput}
                onChange={(e) =>
                    setTrackData({
                        ...trackData,
                        title: e.target.value
                    })
                }
            />
            <div className={classes.fileInputWrap}>
                <div className={classes.fileInput}>
                    <Typography color="secondary" variant="p">
                        Audio File
                    </Typography>
                    <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                            setTrackData({
                                ...trackData,
                                audioFile: base64
                            })
                        }
                    />
                </div>
            </div>
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ marginBottom: 2 }}
                onClick={finishTrack}
                disabled={!hasAllInfo}
            >
                Submit Track
            </Button>
        </Box>
    );
});

export default AddTrackRow;
