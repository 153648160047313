import React, { useState, useEffect, useCallback } from 'react';
import useStyles from './styles';
import {
    TextField,
    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { useParams } from 'react-router-dom';
import AiImagePromptModal from './AiImagePromptModal';

// utils
import FileBase from 'react-file-base64';
import { getIsValidFileType } from '../../../../utils/posts';

// redux
import { useDispatch } from 'react-redux';
import { createPost, updatePost, getPost } from '../../../../actions/posts';
import { useSelector } from 'react-redux';

const SingleTrackForm = React.memo(({ currentTabIndex, index }) => {
    // data
    const [postData, setPostData] = useState({
        title: '',
        message: '',
        tags: '',
        audioFile: '',
        trackImage: '',
        name: '',
        canDownload: false
    });
    const [currentId, setCurrentId] = useState(useParams()?.id);
    const [showAiPrompt, setShowAiPrompt] = useState(false);
    const [error, setError] = useState(null);

    // style
    const classes = useStyles();

    // redux
    const successState = useSelector((state) => state.posts.success);
    const errorState = useSelector((state) => state.posts.error);
    const post = useSelector((state) => (currentId ? state.posts.post : null));
    const user = useSelector((state) => state.auth.authData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentId && currentId !== post?._id) {
            dispatch(getPost(currentId));
        }

        currentId &&
            !!post?.audioFile &&
            setPostData({
                ...post,
                canDownload: post?.canDownload ?? false
            });
    }, [post, currentId, dispatch]);

    const handleSubmit = (e) => {
        e?.preventDefault();
        const isValid = getIsValidFileType(postData?.audioFile);
        if (!isValid) {
            setError('Invalid File Type.  File must be type: mp3');
            return;
        }

        if (!!currentId) {
            dispatch(
                updatePost(currentId, { ...postData, name: user?.result?.name })
            );
        } else {
            dispatch(createPost({ ...postData, name: user?.result?.name }));
        }
        clear();
    };

    const clear = (e) => {
        e?.preventDefault();
        setCurrentId(null);
        setError(null);
        setPostData({
            title: '',
            message: '',
            tags: '',
            audioFile: '',
            trackImage: '',
            name: '',
            canDownload: false
        });
    };

    const onGeneratePress = useCallback(() => {
        setShowAiPrompt(true);
    }, []);

    const closeModal = useCallback(() => {
        setShowAiPrompt(false);
    }, []);

    // render
    if (currentTabIndex !== index) {
        return null;
    }

    return (
        <form
            autoComplete="off"
            noValidate
            className={`${classes.root} ${classes.form}`}
            onSubmit={handleSubmit}
        >
            <Box>
                {successState && (
                    <Typography color="success.main" variant="h6">
                        Successfully added/updated track!
                    </Typography>
                )}
                {errorState && !successState && (
                    <Typography
                        textAlign="center"
                        color="error.main"
                        variant="h6"
                    >
                        {errorState}
                    </Typography>
                )}
                {error && !successState && (
                    <Typography
                        textAlign="center"
                        color="error.main"
                        variant="h6"
                    >
                        {error}
                    </Typography>
                )}
                <Typography textAlign="center" color="secondary" variant="h6">
                    {currentId ? 'EDIT' : 'ADD'} TRACK
                </Typography>
            </Box>
            <TextField
                name="creator"
                variant="filled"
                label="Creator"
                fullWidth
                value={postData.name}
                className={classes.textInput}
                onChange={(e) =>
                    setPostData({
                        ...postData,
                        name: e.target.value
                    })
                }
            />
            <TextField
                name="title"
                variant="filled"
                label="Title"
                fullWidth
                value={postData.title}
                className={classes.textInput}
                onChange={(e) =>
                    setPostData({
                        ...postData,
                        title: e.target.value
                    })
                }
            />
            <Box className="w-full pl-2">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={postData?.canDownload ?? false}
                            className="text-primary"
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    canDownload: e.target.checked
                                })
                            }
                        />
                    }
                    label="Allow Downloads"
                    className="text-white"
                />
            </Box>
            <div className={classes.fileInputWrap}>
                <div className={classes.fileInput}>
                    <Typography color="secondary" variant="p">
                        Audio File
                    </Typography>
                    <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                            setPostData({
                                ...postData,
                                audioFile: base64
                            })
                        }
                    />
                </div>
                <div className={classes.fileInput}>
                    <Typography color="secondary" variant="p">
                        Track Artwork
                    </Typography>
                    <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                            setPostData({
                                ...postData,
                                trackImage: base64
                            })
                        }
                    />
                </div>
                <Box className="flex justify-start items-start w-full">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ marginBottom: 2 }}
                        onClick={onGeneratePress}
                    >
                        Generate AI Image
                    </Button>
                </Box>
            </div>
            <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
            >
                Submit
            </Button>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                type="submit"
                onClick={clear}
                fullWidth
            >
                Clear
            </Button>
            {showAiPrompt ? (
                <AiImagePromptModal open={showAiPrompt} onClose={closeModal} />
            ) : null}
        </form>
    );
});

export default SingleTrackForm;
