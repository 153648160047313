import React, { useCallback, useEffect, useState } from 'react';
import Post from './Post/Post';
import useStyles from './styles';
import { Grid, CircularProgress, Box } from '@mui/material';

// hooks
import { useInView } from 'react-intersection-observer';

// redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPosts } from '../../actions/posts';

const Posts = React.memo(({ onPlayStop, currentId, user }) => {
    // state
    const [isFetching, setIsFetching] = useState(false);

    // data
    const classes = useStyles();
    const posts = useSelector((state) => {
        return state.posts.posts;
    });
    const nextPage = useSelector((state) => state.posts?.nextPage);

    // redux
    const dispatch = useDispatch();

    // actions
    const fetchData = useCallback(
        (page) => {
            if (!page || isFetching) {
                return;
            }

            setIsFetching(true);
            dispatch(getPosts(page, () => setIsFetching(false)));
        },
        [dispatch, isFetching]
    );

    const onFeedEndReached = useCallback(() => {
        if (!nextPage) {
            return;
        }

        fetchData(nextPage);
    }, [nextPage, fetchData]);

    // intersection observer
    const onChange = useCallback(
        (inView) => {
            if (!!inView && !!nextPage) {
                onFeedEndReached();
            }
        },
        [onFeedEndReached, nextPage]
    );

    const { ref: inViewRef } = useInView({
        threshold: 0.5,
        onChange
    });

    const setRefs = useCallback(
        (node) => {
            inViewRef(node);
        },
        [inViewRef]
    );

    useEffect(() => {
        !posts?.length && fetchData(1);
    }, [posts]); // eslint-disable-line

    // render
    const renderPost = useCallback(
        (post, idx) => {
            const isLastItem = idx === posts?.length - 1;

            if (!isLastItem) {
                return (
                    <Grid key={post._id} item xs={12}>
                        <Post
                            post={post}
                            onPlayStop={onPlayStop}
                            currentId={currentId}
                            user={user}
                        />
                    </Grid>
                );
            }

            return (
                <Grid key={post._id} item xs={12} ref={setRefs}>
                    <Post
                        post={post}
                        onPlayStop={onPlayStop}
                        currentId={currentId}
                        user={user}
                    />
                </Grid>
            );
        },
        [currentId, onPlayStop, user, posts, setRefs]
    );

    if (!posts?.length) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid
            className={classes.container}
            container
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
        >
            {posts?.map((post, index) =>
                !post?.hasAlbum ? renderPost(post, index) : null
            )}
            {isFetching && (
                <Box mt={2} ml={3}>
                    <CircularProgress />
                </Box>
            )}
        </Grid>
    );
});
export default Posts;
