import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
    media: {
        height: 50,
        width: 50,
        margin: 4,
        borderRadius: '5px'
    },
    border: {
        border: 'solid'
    },
    fullHeightCard: {
        height: '100%'
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '15px',
        padding: 4,
        height: 60,
        position: 'relative',
        backgroundColor: '#000000',
        border: '1px solid #ffffff',
        boxShadow: '4px 4px 10px #B0B3B890'
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'white'
    },
    overlay2: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        color: 'white'
    },
    grid: {
        display: 'flex'
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px'
    },
    title: {
        fontSize: 16,
        marginLeft: 4,
        marginBottom: 0,
        color: '#ffffff'
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    imageTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    playPauseIcon: {
        color: '#ffffff',
        fontSize: 30
    }
});
